import { createReducer } from '../helpers/createReducer'

export const types = {
  SET_IS_REDIRECT_FROM_CMP_RESULT: 'SET_IS_REDIRECT_FROM_CMP_RESULT',
}

const initState = {
  isRedirectFromCMPResult: false,
}

function setIsRedirectFromCMPResult(state, action) {
  return { ...state, isRedirectFromCMPResult: action.isRedirectFromCMPResult }
}

const reducer = createReducer(initState, {
  [types.SET_IS_REDIRECT_FROM_CMP_RESULT]: setIsRedirectFromCMPResult,
})

export default reducer
