import { combineReducers } from '../helpers/combineReducers'
import auth from './auth'
import user from './user'
import assetUpload from './assetUpload'
import redirectFromCMPResult from './redirectFromCMPResult'

export default combineReducers({
  auth,
  user,
  assetUpload,
  redirectFromCMPResult,
})
