import React, { useEffect, useCallback } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { withAuth, ImplicitCallback } from '@okta/okta-react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import useGlobalState from 'globalState'
import auth from './authConfig'
import { isEmpty } from 'lodash'
import { checkAccessTokenExpired } from 'utils/check-access-token'

const Auth = loadable(() => import('./views/Auth'))
const Home = loadable(() => import('./views/Home'))

// PropTypes
export const propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
}

// DefaultProps
export const defaultProps = {}

function Redirection(props) {
  const [state, dispatch] = useGlobalState()
  const { isAuthenticated } = state.auth

  const { auth: authHelpers, location } = props
  const { pathname } = location

  const login = useCallback(() => {
    if (checkAccessTokenExpired()) window.localStorage.clear()

    auth.login(authHelpers)
  }, [authHelpers])

  const logout = useCallback(() => auth.logout(authHelpers), [authHelpers])

  const routePath = isAuthenticated ? '/home' : ['/auth', '/auth?isRedirect=true']

  const redirectPath = isAuthenticated ? '/home' : '/auth'

  const routeComponent = isAuthenticated ? <Home /> : <Auth important='lowImportant' login={login} />

  useEffect(() => {
    // 如果是 redirect from CMP result page, seachParams 會有 assetProgram, campaignName, campaignStartDate, campaignEndDate
    // 如果 isRedirectFromCMPResult 為 true, login 前先將 assetProgram, campaignName, campaignStartDate, campaignEndDate 存進 sessionStorage
    const currentUrl = new URL(window.location.href)

    const searchProgram = currentUrl.searchParams.get('assetProgram')
    const searchCampaign = currentUrl.searchParams.get('campaignName')
    const searchCampaignStartDate = currentUrl.searchParams.get('campaignStartDate')
    const searchCampaignEndDate = currentUrl.searchParams.get('campaignEndDate')

    const hasExportInfoFromCMP = searchProgram && searchCampaign && searchCampaignStartDate && searchCampaignEndDate
    if (hasExportInfoFromCMP) {
      const exportInfoByCMP = {
        searchProgram,
        searchCampaign,
        searchCampaignStartDate,
        searchCampaignEndDate,
      }

      window.sessionStorage.setItem('exportInfoByCMP', JSON.stringify(exportInfoByCMP))
    }
  }, [])

  const oktaTokenStorage = JSON.parse(window.localStorage.getItem('okta-token-storage'))

  useEffect(() => {
    // 如果是從 CMP redirect 過來, 判斷  searchParams 有沒有 isRedirect, 有的話自動 login
    const currentUrl = new URL(window.location.href)
    const isRedirect = currentUrl.searchParams.has('isRedirect')

    if (isAuthenticated && isEmpty(oktaTokenStorage)) {
      logout()
    }

    if (isRedirect) {
      // // 如果 accessToken 過期，就清除 localStorage
      if (checkAccessTokenExpired()) window.localStorage.clear()

      document.body.style.display = 'none'
      login()
    }
  }, [login, logout, isAuthenticated, oktaTokenStorage])

  useEffect(() => {
    const isRedirectToAuth = pathname === '/auth'
    const isRedirectToRoot = pathname === '/'

    if (isRedirectToAuth || isRedirectToRoot) {
      auth.setAuthenticationState(authHelpers, dispatch)
    }
  }, [authHelpers, dispatch, pathname])

  // 偵測是否出現 authError，如果有出現就重新導回根目錄
  useEffect(() => {
    if (pathname !== '/implicit/callback') return

    const successMessage = document.createElement('h2')

    successMessage.style.cssText = `
      padding: 10px;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 10;
    `
    successMessage.textContent = 'Logged in successfully'

    document.getElementById('root').appendChild(successMessage)

    setTimeout(() => {
      if (window.location.href.includes('home')) {
        const root = document.getElementById('root')
        const successMessage = root.childNodes[1]
        return root.removeChild(successMessage)
      }

      login()
    }, 3000)
  }, [pathname, login])

  return (
    <Switch>
      <Route path='/implicit/callback' component={ImplicitCallback} />
      <Route strict sensitive path={routePath}>
        {routeComponent}
      </Route>
      <Redirect replace from='/' to={redirectPath} />
    </Switch>
  )
}

Redirection.propTypes = propTypes
Redirection.defaultProps = defaultProps

export default withRouter(withAuth(Redirection))
